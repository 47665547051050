/* Global Styles */

*{
  font-family: 'Optima';
}

.App {
  background-color: rgb(255, 255, 255);
}

h1 {
  color: #C57226;
  font-size: 5vw;
  font-weight: bold;
  text-transform: uppercase;
}

h2 {
  font-size: 1.4vw;
  text-align: center;
  color: black;
}

p {
  color: black;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

/* Home Page */

.body {
  height: 100vh;
  display: flex;
  background-image:
    linear-gradient(rgba(248, 248, 248, 0.99), rgba(242, 242, 248, 0.9)),
    url("../public/bg/image-background-events.jpeg");
  background-repeat: no-repeat;
  background-size: contain;
}

.body img {
  opacity: 0.4;
}

/* Our Services Page */

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image:
    linear-gradient(rgba(253, 253, 253, 0.99), rgba(84, 84, 85, 0.90)),
    url("../public/bg/image-background-services.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Our Cars Page */

.our-cars {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image:
    linear-gradient(rgba(255, 255, 255, 0.99), rgba(85, 84, 84, 0.95)),
    url("../public/bg/image-background-garage.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.cars {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Events Page */

.events {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image:
    linear-gradient(rgba(248, 248, 248, 0.99), rgba(84, 84, 85, 0.9)),
    url("../public/bg/image-background-events.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* Contact Page */

.contact {
  background-image:
    linear-gradient(rgba(255, 255, 255, 0.98), rgba(85, 84, 84, 0.87)),
    url("../public/bg/image-background-service.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: center;
}


.info {  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;  
}

.call-us,
.write-us,
.email-us { 
  width: auto;
  height: auto;
  margin: 3%;
  color: white;
  border: 1px rgba(128, 128, 128, 0.25) solid;
  background-color: rgba(252, 252, 252, 0.15);
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 5px 1px rgb(25, 26, 26);
}

.info p {
  font-size: x-large;
  font-weight: bold;
}

.icon,
.email-icon {
  font-size: 6vw;
}

.icon:hover,
.email-icon:hover {
  transform: scale(1.4);
  filter: blur(0.1px);
  cursor: pointer;
}

/* Reservation Page */
.reservation {
  width: 50%;
  margin: auto;
}

form {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: 1px rgba(128, 128, 128, 0.25) solid;
  background-color: rgba(29, 27, 27, 0.15);
  margin-top: 5%;
  margin-bottom: 10%;
  border-radius: 8px;
  box-shadow: 0px 4px 5px 1px rgba(7, 7, 7, 0.9);
}

.inputs {
  flex: 1;
}

input,
textarea,
.PhoneInput {
  margin: 2%;
  font-weight: bold;
  text-transform: uppercase;
  width: 80%;
  border-radius: 8px;
  color :black;
}

input,
.PhoneInput {
  height: 50px;
  justify-content: center;
}

input:hover,
textarea:hover,
PhoneInput:hover {
  cursor: text;
}

input::placeholder,
textarea::placeholder {
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

textarea {
  height: 480px;
  flex: 1;
}

.send-button {
  color: white;
  background-color: #c57226;
  font-size: 1.7vw;
  border: transparent;
}

.send-button:hover {
  cursor: pointer;
}

.recaptcha {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.alert {
  position: absolute;
  top: 30px; 
  width: 100%;
}

/* Bottom Banner */

.bottom-banner {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: rgb(1, 1, 1);
  margin: 0.5%;;
  bottom: 0;
}

.bottom-banner a {
  text-decoration: none;
  font-style: italic;
  color: #C57226;
}

/* 1024px — 1200px: Desktops, large screens */
@media (min-width: 1024px) {

  /* Home Page */
  .body {
      position: relative;
      height: 100vh;
      display: flex;
      flex-direction: column;
      text-align: center;
      background-size: contain;
    }
  
    .home-title {
      text-transform: uppercase;
      letter-spacing: .7em;
      display: inline-block;
      border: 4px double rgba(255, 255, 255, .25);
      border-width: 4px 0;
      position: absolute;
      
      left: 0;
	    right: 0;
      top: 12%;
	    margin: auto;
      width: 40em;
      font-size: 2rem;
    }
  
    .home-paragraph {
      margin-top: 2%;
    }

  .home-paragraph p{
    font-size: 1.75rem;   
  }

  /* Our Services Page */

  .services p {
    font-size: 1.1vw;
  }

  /* Our Cars Page */

  .our-cars h1 {
    font-size: 5vw;
  }

  .our-cars p {
    font-size: 1.1vw;
  }

  .car p {
    width: 35vh;
  }

  /* Events Page */
  .events h1 {
    font-size: 5vw;
  }

  .events p {
    font-size: 1.1vw;
    margin-left: 20%;
    margin-right: 20%;
  }
  /* Reservation Page */

  .send-button {
    font-size: 2.4vw;
    margin: auto auto 2% auto;
  }

  /* Bottom Banner */

  .bottom-banner {
    font-size: 0.9rem;
  }
}

/* 769px — 1023px: Small screens, laptops */
@media (min-width: 768px) and (max-width: 1023px) {

  /* Home Page */

  .home-title {
    text-transform: uppercase;
    letter-spacing: .7em;
    display: inline-block;
    border: 4px double rgba(255,255,255,.25);
    border-width: 4px 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 12%;
    margin: auto;
    width: 100vw;
    font-size: 1.5rem;    
  }

  .body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-size: contain;
  }

  .home-paragraph{
    margin-top: 10%;
  }

  .home-paragraph p{
    font-size: 2.5rem;   
  }

  /* Our Services Page */

  .cards {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .card {
    justify-content: space-around;
  }

  .services h1 {
    font-size: 7vw;
  }

  .services h2 {
    font-size: 5vw;
    margin: 2%;
  }

  .services p {
    font-size: 3vw;
    margin: 5%;
  }

  /* Our Cars Page */
  .our-cars h1 {
    font-size: 7vw;
  }

  .our-cars h2 {
    font-size: 5vw;
  }

  .our-cars p {
    font-size: 3vw;
  }

  /* Events Page */
  .events h1 {
    color: #C57226;
    font-size: 7vw;
    font-weight: bold;
  }

  .events h2 {
    font-size: 5vw;
  }
  .events p {
    font-size: 3vw;
  }

  /* Contact Page */

  .contact h1 {
    font-size: 5vw;
  }
  
  .call-us,
  .write-us,
  .email-us {
    width: auto;
    height: auto;
    margin: 5%;
  }
  
  .info p {
    font-size: 5vw;
    font-weight: bold;
  }

  /* Reservation Page */
  .reservation {
    width: 80%;
    margin: auto;
  }

  .reservation h1 {
    margin: auto;
    text-align: center;
  }

  .send-button {
    font-size: 4vw;
    margin: auto auto 2% auto;
  }

  /* Bottom Banner */

  .bottom-banner {
    font-size: 0.8rem;
  }

}

/* 482px — 767px: iPads, Tablets */
@media (min-width: 482px) and (max-width: 767px) {

  /* Home Page */

  .home-title {
    text-transform: uppercase;
    letter-spacing: .7em;
    display: inline-block;
    border: 4px double rgba(255,255,255,.25);
    border-width: 4px 0;
    position: absolute;
 
    width: 100vw;

    left: 0;
    right: 0;
    top: 12%;
    margin: auto;

  }

  .body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-size: contain;
  }
  
  .home-paragraph{
    margin-top: 25%;
  }

  .home-paragraph p{
    font-size: 2.2rem;   
  }



  /* Our Services Page */
  .cards {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .services h1 {
    font-size: 7vw;
  }

  .services h2 {
    text-align: center;
    font-size: 4vw;
    margin: 2%;
  }

  .services p {
    font-size: 2.5vw;
    margin: 5%;
  }

  .card {
    justify-content: space-around;
  }

  /* Our Cars Page */

  .our-cars h1 {
    font-size: 7vw;
  }

  .our-cars h2 {
    font-size: 4vw;
    margin: 5%;
  }

  .our-cars p {
    text-align: center;
    font-size: 2.5vw;
    margin: 5%;
    border: 1px res solid;
  }

  /* Events Page */

  .events h1 {
    font-size: 7vw;
  }

  .events h2 {
    font-size: 4vw;
  }

  .events p {
    font-size: 2.5vw;
  }

  /* Contact Page */

  .contact h1 {
    font-size: 7vw;
  }

  .call-us,
  .write-us,
  .email-us {
    height: auto;
    margin: 5%;
  }

  .info p {
    font-size: 5vw;
    font-weight: bold;
  }

  /* Reservation Page */

  .reservation {
    width: 85%;
    margin: auto;
  }

  .reservation h1 {
    margin: auto;
    text-align: center;
  }

  .send-button {
    font-size: 4vw;
    margin: auto auto 2% auto;
  }

  /* Bottom Banner */

  .bottom-banner {
    font-size: 0.8rem;
  }

}

/* 10px — 481px: Mobile devices */
@media (min-width: 10px) and (max-width: 481px) {

  /* Home Page */   
  
  .home-title {
    text-transform: uppercase;
    letter-spacing: .7em;
    display: inline-block;
    border: 4px double rgba(255,255,255,.25);
    border-width: 4px 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 8%;
    margin: auto;
    width: 100vw;
  }

  .body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-size: contain;
    background-color: white;
  }

  .home-paragraph{
    margin-top: 20%;
  }

  .home-paragraph p{
    font-size: 1.5rem;   
  }

  /* Our Services Page */

  .services h1 {
    font-size: 7vw;
  }

  .services h2 {
    font-size: 5vw;
    margin: 5%;
  }

  .services p {
    font-size: 3vw;
    margin: 5%;
  }

  .card {
    justify-content: space-around;
  }

  /* Our Cars Page */

  .our-cars h1 {
    font-size: 7vw;
  }

  h2 {
    font-size: 5vw;
    margin: 5%;
  }

  p {
    font-size: 3vw;
    margin: 5%;
  }

  .cars {
    width: 50vw;
  }

  /* Events Page */

  .events h1 {
    font-size: 7vw;
  }

  .events h2 {
    font-size: 5vw;
  }

  .events p {
    font-size: 3vw;
  }

  /* Contact Page */

  .contact h1 {
    font-size: 7vw;
  }

  .call-us,
  .write-us,
  .email-us {
    width: auto;
    height: 12ch;
    margin: 5%;
  }

  .info p {
    font-size: 5vw;
    font-weight: bold;
  }


  /* Reservation Page */
  .reservation {
    height: 100vh;
    width: 90%;
  }

  .reservation h1 {
    font-size: 7vw;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    border: 1px rgba(128, 128, 128, 0.25) solid;
    background-color: rgba(255, 255, 255, 0.15);
    margin-top: 5%;
  }

  .line{
    display: flex;
    align-items: center;
  }

  input,
  textarea,
  .PhoneInput {
    font-size: 0.6rem;
  }

  .send-button {
    font-size: 6vw;
    margin: auto auto 2% auto;
  }

  /* Bottom Banner */

  .bottom-banner {
    font-size: 0.5rem;
  }
}