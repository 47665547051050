.slider {
    display: flex;
    position: relative;
    height: 60vh;
    justify-content: center;
    align-items: center;
}

.image {
    width: auto;
    max-width: 75vw;
    height: 35vh;
    max-height: 35vh;
    border-radius: 12px;
    box-shadow: 0px 4px 5px 4px rgb(25, 26, 26);
  }

.right-arrow {
    position: absolute;
    top: 70%;
    right: 20%;
    font-size: 2rem;
    color: rgba(197, 114, 38, 0.5);
    z-index: 1;
    cursor: pointer;
    user-select: none;
  }
    
.left-arrow {
    position: absolute;
    top: 70%;
    left: 20%;
    font-size: 2rem;
    color: rgba(197, 114, 38, 0.5);
    z-index: 1;
    cursor: pointer;
    user-select: none;
  }
    
.slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
    
.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }