.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 10%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    list-style-type: none;
    background-color: #000000;
    box-shadow: 0 20px 10px 0 rgba(247, 246, 246, 0.09);
}

ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.nav-item {
    display: flex;
    text-align: center;
    color: #FFFAFA;
    margin: 0.1%;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.5rem;
}

.nav-item:hover {
    color: #5a5856;
    cursor: pointer;
    box-shadow: 0 1px 1px 0 #C57226;
}



/* 1025px — 1200px: Desktops, large screens */
@media (min-width: 1025px) {

    .nav-item {
        margin-right: 20px;
    }

    .logo {
        width: 180px;
        height: 60px;
        margin: 0;
        display: block
    }
}

/* 769px — 1024px: Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {

    .nav-item {
        margin-right: 15px;
        font-size: 1.1rem;
    }

    .logo {
        width: 140px;
        height: auto;
    }

}

/* 481px — 768px: iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
    .nav-item {
        margin-right: 15px;
        font-size: 1.1rem;
    }

    .logo {
        width: 100px;
        height: auto;
    }
}

/* 320px — 480px: Mobile devices */
@media (min-width: 10px) and (max-width: 480px) {
    ul {
        font-size: 0.45rem;
    }

    .nav-item {
        margin-right: 15px;
        font-size: 0.45rem;
    }

    .logo {
        width: 50px;
        height: auto;
    }
}