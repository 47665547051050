.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5%;
    border: 1px solid rgba(129, 127, 127);
    border-radius: 8px;
    box-shadow: 0px 1px 3px 1px rgb(25, 26, 26);
}

.card:hover {
    box-shadow: 1px 8px 20px grey;
    -webkit-transition: box-shadow .3s ease-in;
}

.card-title {
    background-color: #535349;
    color: white;
    font-size: larger;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 5vh;
    border-radius: 8px 8px 0 0;
    border: 1px 1px 1px 1px solid black;
    text-transform: uppercase;
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    background-color: rgba(116, 112, 112, 0.2);
    font-style: italic;
    text-align: center;
    width: 100%;
    height: 100%;
    min-height: 7vh;
}

.text p {
    font-size: 1rem;
}

.card img {
    width: 100%;
    height: auto;
    min-height: auto;
    border: 0px 1px 0px 1px solid black;
}


/* 1025px — 1200px: Desktops, large screens */
@media (min-width: 1025px) {

    .card {
        width: 20%;
        height: auto;
    }
    
    .card-title {
        font-size: 1rem;
    }

    .card img {
        width: 100%;
        height: auto;
    }

    .card .text {
        min-height: 5vh;
    }

    .text p {
        font-size: 1rem;
    }

}

/* 769px — 1024px: Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {

    .card-title {
        font-size: 1.25rem;
    }

    .card img {
        width: 100%;
        height: auto;
    }

    .card .text {
        min-height: 5vh;
    }

    .text p {
        font-size: 1.1rem;
    }

}

/* 481px — 768px: iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {

    .card-title {
        font-size: 1.6rem;
    }

    .card img {
        width: 100%;
        height: auto;
    }

    .card .text {
        min-height: 5vh;
    }

    .text p {
        font-size: 1.6rem;
    }

}

/* 320px — 480px: Mobile devices */
@media (min-width: 10px) and (max-width: 480px) {

    .card-title {
        font-size: 1.2rem;
    }

    .card img {
        width: 100%;
        height: auto;
    }

    .card .text {
        min-height: 5vh;
    }

    .text p {
        font-size: 1rem;
    }

}